var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left" }, [
        _c("section", [
          _c("h3", [
            _vm._v(_vm._s(_vm.timeSign) + "，" + _vm._s(_vm.realname) + "！")
          ]),
          _c("p", [_vm._v("欢迎使用由英海瑞特科技研发系统，祝您工作愉快~")])
        ])
      ]),
      _c("div", { staticClass: "right" }, [
        _c("section", [
          _c("h3", [_vm._v("车辆总数")]),
          _c("p", [
            _c("span", [_vm._v(_vm._s(_vm.revenue.vehicleTotal || 0))]),
            _vm._v("辆 ")
          ])
        ]),
        _c("section", [
          _c("h3", [_vm._v("在线车辆(实时)")]),
          _c("p", [
            _c("span", [_vm._v(_vm._s(_vm.revenue.vehicleOnline || 0))]),
            _vm._v("辆 ")
          ])
        ]),
        _c("section", [
          _c("h3", [_vm._v("在线率")]),
          _c("p", [
            _c("span", [_vm._v(_vm._s(_vm.revenue.onlineRate || 0))]),
            _vm._v("% ")
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "item item1" }, [
        _c("div", [
          _c("section", [
            _c("h3", [_vm._v("报警总数")]),
            _c("p", [
              _c("span", [_vm._v(_vm._s(_vm.alarmTotal.alarmCount || 0))])
            ])
          ]),
          _c("section", [
            _c("h3", [_vm._v("ADAS报警数")]),
            _c("p", [_c("span", [_vm._v(_vm._s(_vm.alarmTotal.adas || 0))])])
          ]),
          _c("section", [
            _c("h3", [_vm._v("DSM报警数")]),
            _c("p", [_c("span", [_vm._v(_vm._s(_vm.alarmTotal.dsm || 0))])])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "item item2" },
        [_c("charts", { attrs: { option: _vm.item2Option, id: _vm.item2 } })],
        1
      ),
      _c(
        "div",
        { staticClass: "item item3" },
        [_c("charts", { attrs: { option: _vm.item3Option, id: _vm.item3 } })],
        1
      ),
      _c("div", { staticClass: "item item4" }, [
        _c(
          "div",
          { staticClass: "pieEcharts" },
          [_c("echartsPie", { attrs: { option: _vm.item4Option } })],
          1
        ),
        _c(
          "div",
          { staticClass: "percent" },
          _vm._l(_vm.percentOption, function(item, index) {
            return _c("div", { key: index, staticClass: "singleP" }, [
              _c("div", {
                staticClass: "singleDiv",
                style: { borderColor: item.color }
              }),
              _c("span", [_vm._v(_vm._s(item.name))]),
              _c("span", { staticClass: "singleNum" }, [
                _vm._v(_vm._s(_vm.formatRate(item.value / _vm.percentTotal)))
              ])
            ])
          }),
          0
        )
      ]),
      _c("div", { staticClass: "item item5" }, [
        _c("p", { staticClass: "title" }, [_vm._v("车辆报警排名")]),
        _c(
          "div",
          { staticClass: "rank-table" },
          [
            _c(
              "el-table",
              { attrs: { data: _vm.item5Data } },
              [
                _c("el-table-column", {
                  attrs: { label: "排行", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", { class: "rank" + scope.row.sort }, [
                            _vm._v(" " + _vm._s(scope.row.sort) + " ")
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cph",
                    label: "车牌号",
                    "show-overflow-tooltip": true
                  }
                }),
                _c("el-table-column", {
                  attrs: { label: "车牌颜色", "show-overflow-tooltip": true },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.typeObj[Number(scope.row.licensePlateColor)]
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "companyName",
                    label: "所属机构",
                    width: "170",
                    "show-overflow-tooltip": true
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "avgRunMileAlarm",
                    label: "百公里报警数",
                    width: "120",
                    "show-overflow-tooltip": true
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(parseInt(scope.row.avgRunMileAlarm)) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "item item6" },
        [_c("charts", { attrs: { option: _vm.item6Option, id: _vm.item6 } })],
        1
      ),
      _c(
        "div",
        { staticClass: "item item7" },
        [_c("charts", { attrs: { option: _vm.item7Option, id: _vm.item7 } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }