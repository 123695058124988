<template>
  <div class="dashboard">
    <div class="header">
      <div class="left">
        <section>
          <h3>{{ timeSign }}，{{ realname }}！</h3>
          <p>欢迎使用由英海瑞特科技研发系统，祝您工作愉快~</p>
        </section>
      </div>
      <div class="right">
        <section>
          <h3>车辆总数</h3>
          <p>
            <span>{{ revenue.vehicleTotal || 0 }}</span
            >辆
          </p>
        </section>
        <section>
          <h3>在线车辆(实时)</h3>
          <p>
            <span>{{ revenue.vehicleOnline || 0 }}</span
            >辆
          </p>
        </section>
        <section>
          <h3>在线率</h3>
          <p>
            <span>{{ revenue.onlineRate || 0 }}</span
            >%
          </p>
        </section>
      </div>
    </div>
    <div class="main">
      <div class="item item1">
        <div>
          <section>
            <h3>报警总数</h3>
            <p>
              <span>{{ alarmTotal.alarmCount || 0 }}</span>
            </p>
          </section>
          <section>
            <h3>ADAS报警数</h3>
            <p>
              <span>{{ alarmTotal.adas || 0 }}</span>
            </p>
          </section>
          <section>
            <h3>DSM报警数</h3>
            <p>
              <span>{{ alarmTotal.dsm || 0 }}</span>
            </p>
          </section>
        </div>
      </div>
      <div class="item item2">
        <charts :option="item2Option" :id="item2"></charts>
      </div>
      <div class="item item3">
        <charts :option="item3Option" :id="item3"></charts>
      </div>
      <div class="item item4">
        <div class="pieEcharts">
          <!-- 饼状图 -->
          <echartsPie :option="item4Option"></echartsPie>
        </div>

        <!-- 百分比 -->
        <div class="percent">
          <div
            v-for="(item, index) in percentOption"
            :key="index"
            class="singleP"
          >
            <div class="singleDiv" :style="{ borderColor: item.color }"></div>
            <span>{{ item.name }}</span>
            <span class="singleNum">{{
              formatRate(item.value / percentTotal)
            }}</span>
          </div>
        </div>
      </div>
      <div class="item item5">
        <p class="title">车辆报警排名</p>
        <div class="rank-table">
          <el-table :data="item5Data">
            <el-table-column label="排行" width="70">
              <template slot-scope="scope">
                <span :class="'rank' + scope.row.sort">
                  {{ scope.row.sort }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="cph"
              label="车牌号"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column label="车牌颜色" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{ typeObj[Number(scope.row.licensePlateColor)] }}
              </template>
            </el-table-column>
            <el-table-column
              prop="companyName"
              label="所属机构"
              width="170"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="avgRunMileAlarm"
              label="百公里报警数"
              width="120"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                {{ parseInt(scope.row.avgRunMileAlarm) }}
              </template></el-table-column
            >
          </el-table>
        </div>
      </div>
      <div class="item item6">
        <charts :option="item6Option" :id="item6"></charts>
      </div>
      <div class="item item7">
        <charts :option="item7Option" :id="item7"></charts>
      </div>
    </div>
  </div>
</template>

<script>
import charts from '@/components/charts/charts.vue'
import echartsPie from '@/components/echartsPie/echartsPie.vue'
import {
  getOnlineRate,
  getAlarmAdas,
  getAlarmDsm,
  queryAlarmRankVehicle,
  queryAvgAlarm,
  queryAlarmTotal,
  queryIllegalVehicleTotal,
  queryVehicleCount
} from '@/api/lib/gps-api.js'
import { formatDay, formatDict } from '@/common/utils/index.js'

import { queryDictsByCodes } from '@/api/lib/api.js'

export default {
  components: {
    charts,
    echartsPie
  },
  data () {
    return {
      realname: null,
      item2: 'itemChart2',
      item2Option: {},
      item3: 'item3',
      item3Option: {},
      item4: 'item4',
      item4Option: {},
      item6: 'item6',
      item6Option: {},
      item7: 'item7',
      item7Option: {},
      item3Value: {},
      item4Data: [],
      item5Data: [],
      percentTotal: 1,
      percentOption: [],
      color: ['#336FFE', '#FFD616', '#EA546D', '#02CDCE'],
      form: { days: 7 },
      alarmTotal: {
        adas: 0,
        alarmCount: 0,
        dsm: 0
      },
      revenue: {
        vehicleTotal: 0,
        vehicleOnline: 0,
        onlineRate: 0
      },
      modelObj: null,
      typeObj: null
    }
  },
  methods: {
    /**ADAS警报类型分布 */
    renderADAS () {
      /* 饼状图 */
      let total = 0
      getAlarmAdas().then((res) => {
        if (res.code === 1000) {
          res.data.forEach((element, i) => {
            total += element.value
            element.color = this.color[i]
          })
          this.percentTotal = total === 0 ? 1 : total
          this.percentOption = [...res.data]
          this.item4Option = {
            title: {
              text: 'ADAS警报类型分布',
              left: 0,
              textStyle: {
                color: '#666666',
                fontSize: 16
              },
              top: 0
            },
            grid: {
              top: 45,
              left: 10,
              right: 40,
              bottom: 20
            },

            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            color: this.color,
            series: [
              {
                name: '报警类型',
                type: 'pie',
                radius: ['60%', '70%'],
                center: ['50%', '60%'],
                avoidLabelOverlap: false,
                label: {
                  normal: {
                    show: false,
                    position: 'center'
                  },
                  emphasis: {
                    show: true,
                    textStyle: {
                      fontSize: '14',
                      color: '#666'
                    }
                  }
                },
                labelLine: {
                  normal: {
                    show: false
                  }
                },
                data: res.data
              }
            ]
          }
        }
      })
    },
    /**DSM报警数量 */
    renderDSM () {
      getAlarmDsm().then((res) => {
        if (res.code === 1000) {
          this.item2Option = {
            title: {
              text: 'DSM报警数量',
              left: 0,
              textStyle: {
                color: '#666666',
                fontSize: 16
              },
              top: 0
            },
            grid: {
              top: 45,
              left: '8%',
              right: 30,
              bottom: 20
            },
            tooltip: {
              show: true,
              trigger: 'axis',
              textStyle: {
                fontSize: 12
              }
            },
            color: ['#FFD616', '#AD8FDF', '#02CDCE', '#EA546D', '#336FFE'],
            legend: {
              right: 5,
              textStyle: {
                color: '#333'
              },
              orient: 'horizontal',
              itemWidth: 4,
              itemHeight: 4,
              itemGap: 10
            },
            dataset: {
              dimensions: [
                'product',
                '打电话',
                '疲劳驾驶',
                '抽烟报警',
                '不目视前方',
                '其他类型'
              ],
              source: res.data
            },
            xAxis: {
              type: 'category',
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#D9D9D9'
                }
              },
              axisTick: {
                lineStyle: {
                  color: '#D9D9D9'
                }
              },
              axisLabel: {
                color: '#666666',
                fontSize: 12
              },
              splitLine: {
                show: false
              }
            },
            yAxis: {
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                color: '#666666',
                fontSize: 12
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E9E9E9'
                }
              }
            },
            series: [
              { type: 'bar', barWidth: '8%' },
              { type: 'bar', barWidth: '8%' },
              { type: 'bar', barWidth: '8%' },
              { type: 'bar', barWidth: '8%' },
              { type: 'bar', barWidth: '8%' }
            ]
          }
        }
      })
    },
    /** 百公里报警趋势 */
    renderAlarmTrend () {
      queryAvgAlarm(this.form).then((res) => {
        if (res.code === 1000) {
          let xdata = []
          let ydata = []
          res.data.forEach((element) => {
            xdata.push(element.statisticsDate)
            ydata.push(element.avgRunMileAlarm)
          })

          /* 折线图 */
          this.item3Option = {
            title: {
              text: '百公里报警趋势',
              left: 0,
              textStyle: {
                color: '#666666',
                fontSize: 16
              },
              top: 0
            },
            grid: {
              top: 45,
              left: 40,
              right: 40,
              bottom: 20
            },
            tooltip: {
              show: true,
              trigger: 'axis',
              textStyle: {
                fontSize: 12
              },
              formatter: '{b0}<br /> 百公里报警数:{c0}<br />'
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#D9D9D9'
                }
              },
              axisTick: {
                lineStyle: {
                  color: '#D9D9D9'
                }
              },
              axisLabel: {
                color: '#666666',
                fontSize: 12
              },
              splitLine: {
                show: false
              },
              data: xdata
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                color: '#666666',
                fontSize: 12
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E9E9E9'
                }
              }
            },
            series: [
              {
                data: ydata,
                type: 'line',
                smooth: true,
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: 'rgba(81, 132, 254, 0.2)' // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'rgba(51, 111, 254, 0)' // 100% 处的颜色
                      }
                    ],
                    global: false // 缺省为 false
                  }
                },
                itemStyle: {
                  normal: {
                    color: '#336FFE',
                    lineStyle: {
                      color: '#336FFE'
                    }
                  }
                }
              }
            ]
          }
        }
      })
    },
    /**车辆在线率 */
    renderOnlineRate () {
      getOnlineRate().then((res) => {
        if (res.code === 1000) {
          this.item7Option = {
            title: {
              text: '车辆在线率',
              left: 0,
              textStyle: {
                color: '#666666',
                fontSize: 16
              },
              top: 0
            },
            grid: {
              top: 45,
              left: 40,
              right: 40,
              bottom: 20
            },
            tooltip: {
              show: true,
              trigger: 'axis',
              textStyle: {
                fontSize: 12
              },
              formatter: '{b0}<br /> 车辆在线率:{c0}%<br />'
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#D9D9D9'
                }
              },
              axisTick: {
                lineStyle: {
                  color: '#D9D9D9'
                }
              },
              axisLabel: {
                color: '#666666',
                fontSize: 12
              },
              splitLine: {
                show: false
              },
              data: res.data.xdata
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                color: '#666666',
                fontSize: 12
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E9E9E9'
                }
              }
            },
            series: [
              {
                data: res.data.ydata,
                type: 'line',
                smooth: true,

                itemStyle: {
                  normal: {
                    color: '#336FFE',
                    lineStyle: {
                      color: '#336FFE',
                      shadowBlur: 15,
                      shadowColor: '#5184FE',
                      shadowOffsetX: 5,
                      shadowOffsetY: 5
                    }
                  }
                }
              }
            ]
          }
        }
      })
    },
    /**违章车辆报警统计 */
    renderAlarmVehicle () {
      queryIllegalVehicleTotal(this.form).then((res) => {
        if (res.code === 1000) {
          let trackDeviate = 0,
            sleepFatigue = 0,
            speed = 0,
            workFatigue = 0
          if (res.data) {
            ; (trackDeviate = res.data.trackDeviate),
              (sleepFatigue = res.data.sleepFatigue),
              (speed = res.data.speed),
              (workFatigue = res.data.workFatigue)
          }
          this.item6Option = {
            title: {
              text: '违章车辆报警统计',
              left: 0,
              textStyle: {
                color: '#666666',
                fontSize: 16
              },
              top: 0
            },
            grid: {
              height: '100%',
              top: '10%',
              left: '10',
              right: 40,
              bottom: '10%',
              containLabel: true
            },
            tooltip: {
              show: true,
              trigger: 'axis',
              textStyle: {
                fontSize: 12
              }
            },
            xAxis: {
              show: false
            },
            yAxis: {
              inverse: true,
              type: 'category',
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#D9D9D9'
                }
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                margin: 100,
                textStyle: {
                  align: 'left',
                  color: '#666666',
                  fontSize: 12
                }
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E9E9E9'
                }
              },
              data: ['车道偏移', '生理疲劳', '超速', '超时疲劳']
            },
            series: [
              {
                data: [trackDeviate, sleepFatigue, speed, workFatigue],
                type: 'bar',
                barWidth: '20',
                label: {
                  normal: {
                    show: true,
                    position: 'right',
                    color: '#333',
                    fontSize: 12
                    /* formatter: function(param) {
                    return param.value + '%';
                },*/
                  }
                },
                itemStyle: {
                  normal: {
                    color: '#FFD616',
                    lineStyle: {
                      color: '#FFD616'
                    }
                  }
                }
              }
            ]
          }
        }
      })
    },
    /**车辆报警排名 */
    renderRankVehicle () {
      queryAlarmRankVehicle({ days: 1 }).then((res) => {
        if (res.code === 1000) {
          this.item5Data = res.data
        }
      })
    },
    queryAlarmTotal () {
      queryAlarmTotal({ days: 1 }).then((res) => {
        if (res.code === 1000) {
          let alarmTotal = {
            adas: 0,
            alarmCount: 0,
            dsm: 0
          }
          this.alarmTotal = res.data || alarmTotal
        }
      })
    },
    queryVehicleCount () {
      queryVehicleCount({ system: sessionStorage.getItem('system') }).then(
        (res) => {
          if (res.code === 1000) {
            this.revenue = res.data
          }
        }
      )
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'VETY,CPYS' }).then((res) => {
        if (res.code === 1000) {
          this.modelObj = formatDict(res.data.VETY)
          this.typeObj = formatDict(res.data.CPYS)
        }
      })
    },
    /** 数据% */
    formatRate (cellValue) {
      return cellValue == null || cellValue === ''
        ? '-'
        : `${this.formatNumber(cellValue, 100)}%`
    },
    /** 整数||小数 */
    formatNumber (cellValue, number) {
      number = number || 1
      return parseInt(cellValue * number) < parseFloat(cellValue * number)
        ? (cellValue * number).toFixed(2)
        : cellValue * number
    }
  },

  computed: {
    timeSign () {
      const timeSign = new Date().getHours()
      if (timeSign >= 6 && timeSign < 11) {
        return '早上好'
      } else if (timeSign >= 11 && timeSign < 13) {
        return '中午好'
      } else if (timeSign >= 13 && timeSign < 18) {
        return '下午好'
      } else {
        return '晚上好'
      }
    }
  },

  filters: {
    incomeFilter (v) {
      return `¥ ${(v / 100).toFixed(2)}`
    }
  },
  created () {
    this.realname = sessionStorage.getItem('realname') || localStorage.getItem('realname')
    this.getDicts()
  },
  mounted () {
    this.renderADAS()
    this.renderDSM()
    this.renderOnlineRate() /**车辆在线率 */

    this.renderAlarmTrend() /** 报警趋势 最近7天  */
    this.renderAlarmVehicle() /**违章车辆报警统计 最近7天 */

    this.renderRankVehicle() /**车辆报警排名 */
    this.queryAlarmTotal() /**报警统计 */
    this.queryVehicleCount() /**车辆数量统计 */
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  height: 100%;
  overflow: auto;
  @include themify() {
    .header {
      height: 8vh;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      background: themed("w");
      padding: 20px;
      margin-bottom: 20px;

      .left {
        section {
          h3 {
            font-size: (32rem/14);
            font-weight: 400;
            color: themed("b");
            margin: 0 0 1vh 0;
          }
          p {
            font-size: (12rem/14);
            color: themed("n6");
            margin: 0;
          }
        }
      }
      .right {
        display: flex;
        flex-wrap: nowrap;
        section {
          margin-left: 5vw;
          text-align: left;
          h3 {
            font-size: 1rem;
            font-weight: normal;
            color: themed("n6");
            margin: 0 0 1vh 0;
          }
          p {
            font-weight: 600;
            color: themed("n7");
            margin: 0;
            text-align: center;
            span {
              font-size: (26rem/14);
            }
          }
        }
      }
    }
    .main {
      height: calc(120vh - 100px);
      display: grid;
      grid-auto-columns: 1fr 1fr;
      grid-template-rows: 1fr 2fr 2fr 2fr;
      grid-template-areas:
        "a c"
        "b c"
        "d e"
        "g f";
      grid-gap: 12px 20px;
      .item {
        overflow: hidden;
        border-radius: 4px;
      }
      .item:not(.item1) {
        padding: 1.5vh;
        background: themed("w");
      }
      .item1 {
        grid-area: a;

        & > div {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          font-size: 1rem;
          color: themed("w");
          height: 100%;
          section {
            width: calc(33% - 13px);
            border-radius: 4px;
            padding: 4vh 0;
            background-position: right;
            background-size: 35%;
            background-repeat: no-repeat;
            height: calc(100% - 8vh);
            h3 {
              font-weight: normal;
              margin: 0 0 1vh 0;
            }
            p {
              margin: 0;
              span {
                font-weight: 600;
                font-size: (24rem/14);
              }
            }
            &:nth-child(1) {
              background-color: #ff6d31;
              background-image: url("../../../assets/images/gps/bg3.png");
            }
            &:nth-child(2) {
              background-color: #6e31ea;
              background-image: url("../../../assets/images/gps/bg2.png");
            }
            &:nth-child(3) {
              background-color: #336ffe;
              background-size: 43%;
              background-image: url("../../../assets/images/gps/bg1.png");
            }
          }
        }
      }
      .item2 {
        grid-area: b;
      }
      .item3 {
        grid-area: c;
      }
      .item4 {
        grid-area: d;
        display: flex;
        justify-content: space-between;
        .pieEcharts {
          width: 33%;
        }
        .percent {
          width: 64%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          margin-bottom: 10px;
          align-items: center;
          padding-top: 5vh;

          .singleP {
            width: 50%;
            display: flex;
            height: 5vh;
            align-items: center;
            span {
              font-size: 12px;
              display: inline-block;
              color: #333;
              margin-left: 1vh;
            }
            .singleDiv {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              border: solid 2px;
            }

            .singleNum {
              font-size: 14px;
              color: #474747;
              margin-left: 1vh;
            }
          }
        }
      }
      .item5 {
        grid-area: e;
      }
      .item6 {
        grid-area: g;
      }
      .item7 {
        grid-area: f;
      }

      .item5 {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: themed("n7");
          margin: 0 0 1vh;
          padding-left: 0.5vw;
        }
        .rank-table {
          flex: 1;
        }
      }
    }
  }
}
span[class*="rank"] {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  line-height: 2vh;
  text-align: center;
  border-radius: 50%;
}
.theme-project-gps {
  .rank1 {
    background: #336ffe;
    color: #ffffff;
  }
  .rank2 {
    background: rgba($color: #336ffe, $alpha: 0.6);
    color: #ffffff;
  }
  .rank3 {
    background: rgba($color: #336ffe, $alpha: 0.4);
    color: #ffffff;
  }
  .rank4 {
    background: rgba($color: #ebedef, $alpha: 1);
    color: #333;
  }
  .rank5 {
    background: rgba($color: #ebedef, $alpha: 1);
    color: #333;
  }
  .item {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
